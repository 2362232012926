// Importando funciones de funciones.js
import { 
    apertura, llenarCardsBlog, llenarPageBlog, mostrarDetallesBlog, getParameterByName, iniciaSlider,
    navBackground, next_fondo, actBanner, nextBanner, updateActiveClass, llena_bannerspub, updateMessage, cycleMessages,
    reproduce_video_youtube, reproduce_testimonio, cursosNivel, mostrarCurso, registrarEventoEnLaApiMetaAds
} from './funciones.js';    

(function() {
    'use strict';


    let heros = [];      

    // Obtener todos los enlaces del menú en un array
    const menuLinks = Array.from(document.querySelectorAll('.nav_links'));    

    const messages = [
        {title: "ADMINISTRACIÓN"},
        {title: "INVENTARIOS"},
        {title: "NEGOCIACIÓN CON PROVEEDORES"}, 
        {title: "SEGURIDAD ALIMENTARIA"},
        {title: "MEJORA LA EXPERIENCIA DEL CLIENTE"},
        {title: "CREACIÓN DE PROGRAMAS DE LEALTAD"},        
        {title: "IMPLEMENTA EL SERVICIO A DOMICILIO"},        
        {title: "ESTRATEGIAS DE PRECIOS"},        
        {title: "COMUNICACIÓN Y ATENCIÓN AL CLIENTE"},        
        {title: "MARKETING"},        
        {title: "FINANZAS"},        
        {title: "ASPECTOS LEGALES Y FISCALES"},
        {title: "TECNOLOGÍA"},        
        {title: "INTELIGENCIA EN LOS NEGOCIOS"},         
        {title: "FORMACIÓN Y DESARROLLO DE EQUIPOS"},
        {title: "DESARROLLO Y FORMACIÓN DEL PERSONAL"},
        {title: "VENTAS Y RETENCIÓN DE CLIENTES"}, 
        {title: "GESTIÓN DE LA REPUTACIÓN EN LÍNEA"},        
        {title: "REDES SOCIALES"},        
        {title: "BRANDING"},        
        {title: "Y MUCHOS CURSOS MÁS"}
    ];

    let currentIndex = 0;
    const titleElement = document.getElementById('message-title');
    const descriptionElement = document.getElementById('message-description');
    const containerElement = document.getElementById('message-container');
    const indicators = document.querySelectorAll('#indicators span');


    document.addEventListener('DOMContentLoaded', function(){

        //! MENU LATERAL EN VERSIONES TABLET Y MOVIL

        const botMenu = document.getElementById('botMenu');

        if (botMenu) {
            botMenu.addEventListener('click', function(event) {
                event.preventDefault();  // Evitar que la página se mueva al inicio
                apertura();  // Llamar a la función para abrir el menú
            });
        }

        // ! CÓDIGO PARA QUE CUANDO SE DE CLICK EN UN ENLACE DEL MENU CUANDO ES VERSIÓN MÓVIL, SE CIERRE.
        const menu = document.getElementById('navPrincipal');
        const enlacesMenu = document.querySelectorAll('#navPrincipal .nav_links');
        
        enlacesMenu.forEach((enlace) => {
            enlace.addEventListener('click', () => {
                // Verifica si el menú tiene la clase 'menu2'
                if (menu.classList.contains('menu2')) {
                    // Si la tiene, la eliminamos
                    menu.classList.remove('menu2');
                }
            });
        });    

        //! LLENAR CARDS DEL BLOG INFORMATIVO DE LA PÁGINA PRINCIPAL
        const div_blog = document.getElementById('contenedor-cards-blog');

        if (div_blog !== null) {
            llenarCardsBlog(div_blog);  
        };

        //! LLENAR CARDS DEL BLOG INFORMATIVO DE LA PÁGINA DEL BLOG
        const page_blog = document.getElementById('pagina-cards-blog');

        if (page_blog !== null) {
            llenarPageBlog(page_blog);  
        };

        iniciaSlider();

        //! Llena la información de una noticia del BLOG
        const blogId = getParameterByName('id'); 
        if (blogId !== null) {
            mostrarDetallesBlog(blogId);
        }

        //! Función para mostrar los detalles de un curso
        const cursoId = getParameterByName('idc'); 
        if (cursoId !== null) {            
            mostrarCurso(cursoId);

            // Registrando el evento en la API de Conversiones de Meta Ads
            registrarEventoEnLaApiMetaAds("ViewContent", null, null, null)
                .catch(error => {
                    console.error("Hubo un error al enviar el evento a Meta Ads:", error.message);
                });
        }

        //! Carga el evento para aclarar u obscurecer el background del NAV

        window.addEventListener('scroll', function() {
            navBackground();
        })
        navBackground();

        //! Activa la función para hacer dinámico el fondo del hero de la pantalla, para cambiar entre cada imagen.

        if (document.getElementById("fondosHero")) {
            heros = document.querySelectorAll('.fondo_hero');
            setInterval(() => next_fondo(heros), 3000);
        }

        // ! Escuchar el evento de desplazamiento y actualizar la clase 'nav_active'
        // * La cual me sirve para marcar en que sección de la página me encuentro.

        window.addEventListener('scroll', () => updateActiveClass(menuLinks));        

        // ! Actualizar la clase 'nav_active' cuando la página se carga
        window.addEventListener('load', () => updateActiveClass(menuLinks));        

        //! Visualizando los banners de publicidad
        const div_pub = document.getElementById('div_pub');

        if (div_pub !== null) { 
            llena_bannerspub(div_pub);         
        };

        //! Visualizando mensajes informativos en la banda de color del hero
        if (containerElement !== null) {
            updateMessage(messages, titleElement, descriptionElement, indicators, containerElement, currentIndex);
            cycleMessages(messages, titleElement, descriptionElement, indicators, containerElement, currentIndex);
        }

        //! Monitoreando los enlaces del botón PLAY para reproducir vídeos de Youtube en el DOM
        const playLink = document.querySelector('.play');
        if (playLink) {
            document.querySelectorAll('.play').forEach(link => {
                link.addEventListener('click', reproduce_video_youtube);
            }); 
        }

        //! Reproduce el vídeo de una reseña
        if ($('btn-play').length && $('#ventanaVideo').length && $('#iframeVideo').length) {
            

            $('ul.slide-area').on('click', 'btn-play', function(event) {            
                event.preventDefault();

                reproduce_testimonio(this);
            });
    
            $('#ventanaVideo').on('hidden.bs.modal', function () {
                $('#iframeVideo').attr('src', '');
            });
        }

        //! Funcionalidad para abrir y cerrar las Pregunta Frecuentes

        // Asegurar que todos los paneles están correctamente inicializados
        $('.accordion-content').hide(); // Oculta todos los paneles
        $('.accordion-button').attr('aria-expanded', 'false'); // Establece todos como no expandidos

        $('.accordion-button').on('click', function() {
            var isExpanded = $(this).attr('aria-expanded') === 'true';
            $(this).attr('aria-expanded', !isExpanded);
            $(this).next('.accordion-content').slideToggle();

            // Cierra los otros elementos si solo uno debe estar abierto a la vez
            if (!isExpanded) {
                $('.accordion-content').not($(this).next()).slideUp();
                $('.accordion-button').not(this).attr('aria-expanded', 'false');
            }
        });

        //! Mantiene actualizado el año en la línea de los Derechos de Autor (PIE DE PÁGINA)
        document.addEventListener('contentIncluded', () => {
            const year = new Date().getFullYear(); // Obtiene el año actual
            const yearElement = document.getElementById('current-year');
            if (yearElement) {
                yearElement.textContent = year; // Establece el año en el elemento span
            }
        });

        //! CÓDIGO PARA FILTRAR LOS CURSOS DEL NIVEL PROFESIONAL DESDE LA PANTALLA PRINCIPAL
        const botCursosPro = document.getElementById('botCursosPro');

        if (botCursosPro !== null) {
            botCursosPro.addEventListener('click', (event) => {            
                localStorage.setItem("nivelSeleccionado", 2); 
            });
        };

        //! CÓDIGO PARA FILTRAR LOS CURSOS DEL NIVEL MAESTRO DESDE LA PANTALLA PRINCIPAL
        const botCursosMaestro = document.getElementById('botCursosMaestro');

        if (botCursosMaestro !== null) {
            botCursosMaestro.addEventListener('click', (event) => {            
                localStorage.setItem("nivelSeleccionado", 3); 
            });
        };


        //! CÓDIGO PARA LA PÁGINA DE LOS CURSOS 
        //! PARA DETECTAR EL FILTRO PARA MOSTRAR LOS CURSOS SEGÚN EL NIVEL SELECCIONADO
        const secCursos = document.getElementById('secCursos');

        if (secCursos !== null) {
            
            // Obtener todos los botones
            const botones = document.querySelectorAll('.niveles-botones button');

            // Añadir un listener de evento para ejecutar la función cuando se seleccione un nivel
            botones.forEach((boton) => {

                boton.addEventListener('click', (event) => {

                    botones.forEach((btn) => btn.classList.remove('selected'));
                    event.target.classList.add('selected');

                    // Guardar el nivel seleccionado en Local Storage
                    localStorage.setItem("nivelSeleccionado", event.target.value);                    

                    cursosNivel(event.target.value);    
                });
            });

        } else {
            const secDetalleCurso = document.getElementById('fondoCurso');            
            if (secDetalleCurso === null) {            
                localStorage.removeItem("nivelSeleccionado");
            }
        };
        

        // ! Ejecutar cursosNivel con el nivel seleccionado por defecto

        const botonSeleccionado = document.querySelector('.niveles-botones .selected');
        if (botonSeleccionado) {

            // Obtener el nivel guardado en Local Storage, si existe
            const nivelGuardado = localStorage.getItem("nivelSeleccionado");

            // Verificar si hay un nivel guardado o usar el nivel por defecto
            let nivel = nivelGuardado || document.querySelector('.niveles-botones .selected').value;

            // cursosNivel(botonSeleccionado.value);  // Ejecuta la función con el valor del botón seleccionado por defecto
            cursosNivel(nivel);  // Ejecuta la función con el valor del botón seleccionado por defecto

            // Seleccionando visualmentne el botón que estaba guardado en el Local Storage

            document.querySelectorAll(".nivel-btn").forEach(btn => btn.classList.remove("selected"));
            const botonSeleccionado = document.querySelector(`.nivel-btn[value="${nivel}"]`);
            if (botonSeleccionado) botonSeleccionado.classList.add("selected");            

        }        

        //! ------------------------------------------------------
        //* Enviando el formulario de contacto
        //! ------------------------------------------------------

        const contactForm = document.getElementById('contact-form');

        if (contactForm) {
            document.getElementById("contact-form").addEventListener("submit", function (event) {
                event.preventDefault();

                if (grecaptcha.getResponse() === "") {
                    Swal.fire({
                        title: 'Verificación de Seguridad',
                        text: 'Por favor, verifica que no eres un robot.',
                        icon: 'warning',
                        confirmButtonText: 'Cerrar'
                    });

                    return;
                }

                // Captura los valores de los campos del formulario
                const userName = document.getElementById("user_name").value;
                const userPhone = document.getElementById("user_phone").value;
                const userEmail = document.getElementById("user_email").value;
                const userBusiness = document.getElementById("user_business").value;
                const userMessage = document.getElementById("user_message").value;

                // Configura los parámetros para enviar con EmailJS
                const templateParams = {
                    user_name: userName,
                    user_phone: userPhone,
                    user_email: userEmail,
                    user_business: userBusiness,
                    user_message: userMessage
                };

                // Usa EmailJS para enviar el correo
                emailjs.send("service_5apmar9", "template_169bidh", templateParams)
                    .then(function (response) {
                        Swal.fire({
                            title: 'Correo Envíado',
                            text: 'Tu correo fue enviado correctamente. En breve recibirás noticias.',
                            icon: 'success',
                            confirmButtonText: 'Cerrar'
                        });

                        // Envía el evento "Lead" a la API de Meta Ads
                        registrarEventoEnLaApiMetaAds("Lead", userEmail, userPhone, userName);

                        document.getElementById("contact-form").reset();
                        window.location.href = 'graciasporcontactarnos.html';
                    }, function (error) {
                        Swal.fire({
                            title: 'Error',
                            text: 'Hubo un error al enviar el mensaje. Inténtalo nuevamente.',
                            icon: 'error',
                            confirmButtonText: 'Cerrar'
                        });

                        console.error("Error:", error);
                    });
            });
        }

        //! ------------------------------------------------------
        //* Registrando el evento "Contact" en la API de Meta Ads
        //! ------------------------------------------------------

        document.getElementById("enlaceWP").addEventListener("click", function () {

            registrarEventoEnLaApiMetaAds("Contact", null, null, null);

        });

    });
    
})();